import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import {
  TimeBuffer,
  TranscriptBuffer,
  SettingsDuration,
  ModelType,
  deepgram_modal_type,
  deepgram_redact,
  deepgram_language,
} from "../../../helpers/constants";
import {
  CheckBoxLabel,
  CheckboxContainer,
  CustomCheckbox,
  CustomInputField,
  StyledMenuItem,
} from "../../common/FormInputs";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import {
  StyledSelectFormControl,
  StyledDropdownIcon,
} from "../../common/CustomSelect/styles";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
  SecondaryCTAButton,
} from "../../common/Buttons";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import { FormLabel, Select, makeStyles } from "@material-ui/core";

const SettingModal = ({ id, modal, setModal, setRefreshStatus }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [buffer, setBuffer] = useState(200);
  const [duration, setDuration] = useState("");
  const [transcriptBuffer, setTranscriptBuffer] = useState("");
  // const [checked, setChecked] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [noise, setNoise] = useState("");
  const [normalise, setNormalise] = useState("");
  const [temparature, setTemparature] = useState("");
  const [noSthreshold, setNoSthreshold] = useState("");
  const [ratioThreshold, setRatioThreshold] = useState("");
  const [logProbThreshold, setLogProbThreshold] = useState("");
  const [radio, setRadio] = useState("");
  const [timeStamp, setTimeStamp] = useState("");
  const [prompt, setPrompt] = useState("");
  const [beamSize, setBeamSize] = useState("");
  const [bestOf, setBestOf] = useState("");
  const [server, setServer] = useState("");
  const [sleepTime, setSleepTime] = useState(1);
  const [seekStep, setSeekStep] = useState("");
  const [silenceThreshold, setSilenceThreshold] = useState("");
  const [minimumSilence, setMinimumSilence] = useState("");
  const [transcriptionCount, setTranscriptionCount] = useState("");
  const [dgModalType, setDgModalType] = useState("");
  const [dgLanguage, setDgLanguage] = useState("");
  const [dgRedact, setDgRedact] = useState("");
  const [dgUttSplit, setDgUttSplit] = useState("");
  const [dgChannels, setDgChannels] = useState("");
  const [dgCallback, setDgCallback] = useState("");
  const [dgParameters, setDgParameters] = useState([]);
  const [inputFields, setInputFields] = useState([
    { keyword: "", intensifier: "" },
  ]);
  const [utteranceEnd, setUtteranceEnd] = useState("");
  const [endPointing, setEndPointing] = useState("");
  const [audioStyle, setAudioStyle] = useState("");
  const [keepSlience, setKeepSlicence] = useState(100);
  //   const caseId = id?.id;

  useEffect(() => {
    (async function () {
      try {
        const response = await CaseService.GetMeetingcases(id);
        if (response) {
          setDuration(response?.timeInterval);
          setBuffer(response?.bufferTime ? response?.bufferTime : 200);
          setTranscriptBuffer(response?.stopTranscriptionDelay);
          setTranscriptionCount(response.transcriptionProcessingCount);
          setServer(
            response?.isLocalTranscriptionServer === 4
              ? 0
              : response?.isLocalTranscriptionServer
                ? response?.isLocalTranscriptionServer
                : 0,
          );
          // setChecked(response?.isLocalTranscriptionServer === 1 ? true : false);
          setMediaType(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? "base.en"
              : response.transcriptionModelParams.modelType,
          );
          setAudioStyle(
            response.audioEnhancementParams !== null &&
              response?.audioEnhancementParams?.splitOnSilence
              ? 1
              : response.crossExamination
              ? 3
              : response?.isLocalTranscriptionServer === 4
              ? 4
              : 2,
          );
          setNoise(
            response.audioEnhancementParams
              ? response.audioEnhancementParams.noiseCancellation
              : "",
          );
          setNormalise(
            response.audioEnhancementParams
              ? response.audioEnhancementParams.audioNormalisation
              : "",
          );
          setTemparature(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? 0
              : response.transcriptionModelParams?.temperature,
          );
          setNoSthreshold(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? 0.6
              : response.transcriptionModelParams?.noSpeechThreshold,
          );
          setRatioThreshold(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? 2.4
              : response.transcriptionModelParams?.compressionRatioThreshold,
          );
          setLogProbThreshold(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? -1
              : response.transcriptionModelParams?.logProbThreshold,
          );
          setRadio(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? ""
              : response.transcriptionModelParams.conditionOnPreviousText,
          );
          setTimeStamp(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? ""
              : response.transcriptionModelParams.wordTimeStamps,
          );
          setPrompt(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? ""
              : response.transcriptionModelParams.intialPrompt,
          );
          setBeamSize(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? ""
              : response.transcriptionModelParams.beamSize,
          );
          setBestOf(
            response.transcriptionModelParams === null
              ? ""
              : response.transcriptionModelParams.bestOf,
          );
          setSleepTime(
            response?.splitOnSilenceParams !== null &&
              response?.splitOnSilenceParams?.sleepTime
              ? response?.splitOnSilenceParams?.sleepTime
              : 1,
          );
          setSeekStep(
            response?.splitOnSilenceParams !== null &&
              response.splitOnSilenceParams?.seekStep
              ? response.splitOnSilenceParams.seekStep
              : 100,
          );
          setSilenceThreshold(
            response?.splitOnSilenceParams !== null &&
              response.splitOnSilenceParams?.silenceThreshold
              ? response.splitOnSilenceParams.silenceThreshold
              : -50,
          );
          setMinimumSilence(
            response?.splitOnSilenceParams !== null &&
              response.splitOnSilenceParams?.minimumSilence
              ? response.splitOnSilenceParams.minimumSilence
              : 1,
          );
          setKeepSlicence(
            response?.splitOnSilenceParams !== null &&
              response.splitOnSilenceParams?.keepSlience
              ? response.splitOnSilenceParams.keepSlience
              : 100,
          );
          setDgModalType(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? "nova-2-meeting"
              : response.transcriptionModelParams.model,
          );
          setDgLanguage(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? "en-GB"
              : response.transcriptionModelParams.language,
          );
          setDgUttSplit(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response.transcriptionModelParams === null
              ? 0.8
              : response.transcriptionModelParams.utt_split,
          );
          setDgChannels(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
              ? 1
              : response.transcriptionModelParams.channels,
          );
          setDgCallback(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
              ? ""
              : response.transcriptionModelParams.callback,
          );
          setDgRedact(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
              ? ""
              : response.transcriptionModelParams.redact,
          );
          setUtteranceEnd(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
              ? 1000
              : response.transcriptionModelParams.utterance_end_ms,
          );
          setEndPointing(
            Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
              ? 500
              : response.transcriptionModelParams.endpointing,
          );
          if (
            response.transcriptionModelParams === null ||
            response.transcriptionModelParams !== null
          ) {
            if (response?.transcriptionModelParams?.measurements) {
              setDgParameters((prevState) => [...prevState, "measurements"]);
            }
            if (response?.transcriptionModelParams?.dictation) {
              setDgParameters((prevState) => [...prevState, "dictation"]);
            }
            if (response?.transcriptionModelParams?.diarize) {
              setDgParameters((prevState) => [...prevState, "diarize"]);
            }
            if (response?.transcriptionModelParams?.filler_words) {
              setDgParameters((prevState) => [...prevState, "filler_words"]);
            }
            if (response?.transcriptionModelParams?.numerals) {
              setDgParameters((prevState) => [...prevState, "numerals"]);
            }
            if (response?.transcriptionModelParams?.paragraphs) {
              setDgParameters((prevState) => [...prevState, "paragraphs"]);
            }
            if (response?.transcriptionModelParams?.profanity_filter) {
              setDgParameters((prevState) => [
                ...prevState,
                "profanity_filter",
              ]);
            }
            if (response?.transcriptionModelParams?.punctuate) {
              setDgParameters((prevState) => [...prevState, "punctuate"]);
            }
            if (response?.transcriptionModelParams?.utterances) {
              setDgParameters((prevState) => [...prevState, "utterances"]);
            }
            if (response?.transcriptionModelParams?.vad_events) {
              setDgParameters((prevState) => [...prevState, "vad_events"]);
            }
            if (response?.transcriptionModelParams?.interim_results) {
              setDgParameters((prevState) => [...prevState, "interim_results"]);
            }
            if (response?.transcriptionModelParams?.smart_format) {
              setDgParameters((prevState) => [...prevState, "smart_format"]);
            }

            // default value;

            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [...prevState, "measurements"]);
            }
            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [...prevState, "diarize"]);
            }
            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [...prevState, "numerals"]);
            }
            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [
                ...prevState,
                "profanity_filter",
              ]);
            }
            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [...prevState, "punctuate"]);
            }
            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [...prevState, "utterances"]);
            }
            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [...prevState, "vad_events"]);
            }
            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [...prevState, "interim_results"]);
            }
            if (
              Object.keys(response?.transcriptionModelParams).length === 0 ||
              response?.transcriptionModelParams === null
            ) {
              setDgParameters((prevState) => [...prevState, "smart_format"]);
            }
          }
          if (
            response.transcriptionModelParams &&
            response.transcriptionModelParams.keywords
          ) {
            const arrayOfObjects = [];
            response.transcriptionModelParams.keywords.forEach((keyword) => {
              const [word, intensity] = keyword.split(":");
              arrayOfObjects.push({
                keyword: word,
                intensifier: parseInt(intensity),
              });
            });
            setInputFields(arrayOfObjects);
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: COLORS.INPUT_GRAY,
    },
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const actionsData = [
    {
      text: "Noise Cancellation",
      props: setNoise,
      value: noise,
      checked: noise,
    },
    {
      text: "Normalise Audio",
      props: setNormalise,
      value: normalise,
      checked: normalise,
    },
  ];

  const selectAction = [
    {
      text: "Transcription Processing Count",
      props: setTranscriptionCount,
      value: transcriptionCount,
      name: transcriptionCount,
      type: "number",
      placeholder: "Transcription Processing Count",
    },
    {
      text: "Keep Slience (in ms)",
      props: setBuffer,
      value: buffer,
      name: buffer,
      type: "select",
      placeholder: "Select Buffer time",
      menuItem: TimeBuffer,
    },
    {
      text: "Select Duration of each Audio chunk",
      props: setDuration,
      value: duration,
      name: duration,
      type: "select",
      placeholder: "Select Duration",
      menuItem: SettingsDuration,
    },
    {
      text: "Stop transcription buffer time",
      props: setTranscriptBuffer,
      value: transcriptBuffer,
      name: transcriptBuffer,
      type: "select",
      placeholder: "Stop transcription buffer time",
      menuItem: TranscriptBuffer,
    },
  ];

  const modalAction = [
    {
      props: setTemparature,
      value: temparature,
      name: temparature,
      type: "number",
      label: "Temperature",
    },
    {
      props: setNoSthreshold,
      value: noSthreshold,
      name: noSthreshold,
      type: "number",
      label: "No Speech Threshold",
    },
    {
      props: setRatioThreshold,
      value: ratioThreshold,
      name: ratioThreshold,
      type: "number",
      label: "Compression Ratio Threshold",
    },
    {
      props: setLogProbThreshold,
      value: logProbThreshold,
      name: logProbThreshold,
      type: "number",
      label: "LogProb Threshold",
    },
    {
      props: setBeamSize,
      value: beamSize,
      name: beamSize,
      type: "number",
      label: "Beam Size",
    },
    {
      props: setBestOf,
      value: bestOf,
      name: bestOf,
      type: "number",
      label: "Best Of",
    },
  ];

  const radioActions = [
    {
      label: "Condition on previous text",
      props: setRadio,
      value: radio,
      checked: radio,
    },
    {
      label: "Word Timestamps",
      props: setTimeStamp,
      value: timeStamp,
      checked: timeStamp,
    },
  ];

  const audio_style_action = [
    { label: "Live Streaming", value: 4, name: "liveStreaming" },
    {
      label: "Split on Silence",
      value: 1,
    },
    {
      label: "Periodic Split",
      value: 2,
      hidden: false,
    },
    {
      label: "Cross Examination",
      value: 3,
      hidden: false,
    },
  ];

  const servers = [
    {
      label: "DG",
      value: 0,
    },
    {
      label: "Local Transcription Server - G4",
      value: 2,
    },
  ];

  const split_on_slience = [
    {
      props: setKeepSlicence,
      value: keepSlience,
      name: keepSlience,
      type: "number",
      label: "Keep Slience (in ms)",
    },
    {
      props: setSeekStep,
      value: seekStep,
      name: seekStep,
      type: "number",
      label: "Seek step (in ms)",
    },
    {
      props: setSilenceThreshold,
      value: silenceThreshold,
      name: silenceThreshold,
      type: "number",
      label: "Silence threshold (in decibels)",
    },
    {
      props: setMinimumSilence,
      value: minimumSilence,
      name: minimumSilence,
      type: "number",
      label: "Minimum silence length (in seconds)",
    },
    {
      props: setSleepTime,
      value: sleepTime,
      name: sleepTime,
      type: "number",
      label: "Sleep time after every split (in seconds)",
    },
    {
      label: "Transcription Processing Count",
      props: setTranscriptionCount,
      value: transcriptionCount,
      name: transcriptionCount,
      type: "number",
      placeholder: "Transcription Processing Count",
    },
  ];

  const dg_inputs = [
    {
      text: "Model Type",
      props: setDgModalType,
      value: dgModalType,
      name: dgModalType,
      placeholder: "Select Modal type",
      menuItem: deepgram_modal_type,
      type: "select",
    },
    {
      props: setDgLanguage,
      value: dgLanguage,
      name: dgLanguage,
      text: "Language",
      placeholder: "Select language",
      menuItem: deepgram_language,
      type: "select",
    },
    {
      text: "Redact",
      props: setDgRedact,
      value: dgRedact,
      name: dgRedact,
      placeholder: "Select detact",
      menuItem: deepgram_redact,
      type: "select",
    },
    {
      props: setDgUttSplit,
      value: dgUttSplit,
      name: dgUttSplit,
      type: "number",
      label: "Utt Split (P)",
    },
    {
      props: setDgChannels,
      value: dgChannels,
      name: dgChannels,
      type: "number",
      label: "Channels",
    },
    {
      props: setDgCallback,
      value: dgCallback,
      name: dgCallback,
      type: "text",
      label: "Callback (URL)",
    },
    {
      props: setUtteranceEnd,
      value: utteranceEnd,
      name: utteranceEnd,
      type: "number",
      label: "Utterance End (in ms) (S)",
    },
    {
      props: setEndPointing,
      value: endPointing,
      name: endPointing,
      type: "number",
      label: "End Pointing (in ms) (S)",
    },
  ];

  const deepgram_parameters = [
    {
      label: "Measurements (P)",
      value: "measurements",
    },
    {
      label: "Dictation",
      value: "dictation",
    },
    {
      label: "Diarize",
      value: "diarize",
    },
    {
      label: "Filler Words",
      value: "filler_words",
    },
    {
      label: "Numerals",
      value: "numerals",
    },
    {
      label: "Paragraphs (P)",
      value: "paragraphs",
    },
    {
      label: "Profanity Filter",
      value: "profanity_filter",
    },
    {
      label: "Punctuate",
      value: "punctuate",
    },
    {
      label: "Utterances (P)",
      value: "utterances",
    },
    {
      label: "VAD Events (S)",
      value: "vad_events",
    },
    {
      label: "Interim Results (S)",
      value: "interim_results",
    },
    {
      label: "Smart Format",
      value: "smart_format",
    },
  ];

  async function onFormSubmit(caseId) {
    try {
      setLoader({
        state: true,
        message: `Update settings...`,
      });
      const newArray = [];
      await inputFields.forEach((obj) => {
        if (obj.keyword && obj.intensifier) {
          newArray.push(`${obj.keyword}:${obj.intensifier}`);
        }
        return newArray;
      });
      let filterObj = {
        model: dgModalType,
        language: dgLanguage,
        measurements: dgParameters.includes("measurements") ? true : false,
        dictation: dgParameters.includes("dictation") ? true : false,
        diarize: dgParameters.includes("diarize") ? true : false,
        filler_words: dgParameters.includes("filler_words") ? true : false,
        numerals: dgParameters.includes("numerals") ? true : false,
        paragraphs: dgParameters.includes("paragraphs") ? true : false,
        profanity_filter: dgParameters.includes("profanity_filter")
          ? true
          : false,
        punctuate: dgParameters.includes("punctuate") ? true : false,
        redact: dgRedact,
        utterances: dgParameters.includes("utterances") ? true : false,
        vad_events: dgParameters.includes("vad_events") ? true : false,
        interim_results: dgParameters.includes("interim_results")
          ? true
          : false,
        smart_format: dgParameters.includes("smart_format") ? true : false,
        utt_split: dgUttSplit,
        channels: dgChannels,
        callback: dgCallback,
        keywords: newArray,
        utterance_end_ms: utteranceEnd,
        endpointing: endPointing,
      };

      for (let key in filterObj) {
        if (!filterObj[key] && filterObj[key] !== 0) {
          delete filterObj[key];
        }
        if (Array.isArray(filterObj[key]) && filterObj[key].length === 0) {
          delete filterObj[key];
        }
      }

      const payload = {
        timeInterval: duration,
        bufferTime: audioStyle === 1 ? keepSlience : buffer,
        transcriptionProcessingCount: transcriptionCount,
        stopTranscriptionDelay: transcriptBuffer,
        isLocalTranscriptionServer: audioStyle === 4 ? 4 : server,
        crossExamination: audioStyle === 3 ? true : false,
        audioEnhancementParams: {
          splitOnSilence: audioStyle === 1 ? true : false,
          noiseCancellation: noise,
          audioNormalisation: normalise,
        },
        transcriptionModelParams:
          server === 0 || server === 4
            ? filterObj
            : {
                modelType: mediaType,
                intialPrompt: prompt,
                conditionOnPreviousText: radio,
                temperature: temparature,
                wordTimeStamps: timeStamp,
                noSpeechThreshold: noSthreshold,
                compressionRatioThreshold: ratioThreshold,
                logProbThreshold: logProbThreshold,
                beamSize: beamSize,
                bestOf: bestOf,
              },
        splitOnSilenceParams: {
          sleepTime,
          seekStep,
          silenceThreshold,
          minimumSilence,
        },
      };
      const response = await CaseService.caseTranscriptionSettings(
        caseId,
        payload,
      );
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setRefreshStatus(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal(false);
    }
  }

  const handleCheckboxChange = (value) => {
    if (dgParameters.includes(value)) {
      // If the value is already in the array, remove it
      setDgParameters(dgParameters.filter((item) => item !== value));
    } else {
      // Otherwise, add the value to the array
      setDgParameters([...dgParameters, value]);
    }
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { key: "", value: "" }]);
  };

  // Function to handle changing input field values
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  // Function to handle removing input fields
  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  return (
    <Modal open={modal} onClose={() => setModal(false)} disableRestoreFocus>
      <DrawerContainer>
        <Container>
          <HeadingContainer>
            <Heading>{"Transcription Settings"}</Heading>
            <CloseModal
              onClick={() => setModal(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <StyledForm
              style={{
                padding: "20px 2px 20px 0px",
                marginLeft: "10px",
              }}
            >
              <BoxContainer>
                <HeadingsLabel>{"Transcription servers"}</HeadingsLabel>
                <ServerAction>
                  {servers.map((item) => (
                    <LabelContainer>
                      <LabelValueContainer>
                        <CheckboxContainer style={{ marginTop: 12 }}>
                          <CustomCheckbox
                            name="checked"
                            value={item.value}
                            checked={item.value === server}
                            onChange={() => {
                              if (audioStyle === 4) {
                                setAudioStyle("");
                                setServer(item.value);
                              } else {
                                setServer(item.value);
                              }
                            }}
                          />
                          <CheckBoxLabel>{item?.label}</CheckBoxLabel>
                        </CheckboxContainer>
                      </LabelValueContainer>
                    </LabelContainer>
                  ))}
                </ServerAction>
              </BoxContainer>
            </StyledForm>
            <StyledForm
              style={{ padding: "20px 0px 20px 0px", marginRight: "10px" }}
            >
              {server === 1 || server === 2 ? (
                <BoxContainer className="invoice">
                  <HeadingsLabel>{"Model Parameters"}</HeadingsLabel>
                  <ModalParameterContainer>
                    {modalAction.map((modal, index) => (
                      <LabelContainer>
                        <LabelValueContainer>
                          <div
                            // className="form-fields"
                            style={{ paddingTop: 5 }}
                          >
                            <FormLabel
                              style={{
                                fontSize: 12,
                                fontFamily: theme.fonts.primaryFontSemiBold,
                                color: "#949ab0",
                              }}
                            >
                              {modal.label}
                            </FormLabel>
                            <CustomInputField
                              variant="outlined"
                              name={modal.name}
                              type={modal?.type}
                              value={modal.value}
                              onChange={(e) => modal.props(e.target.value)}
                            />
                          </div>
                        </LabelValueContainer>
                      </LabelContainer>
                    ))}
                    <LabelContainer>
                      <LabelValueContainer>
                        <Label>
                          {"Initial Prompt"} (Comma separated words)
                        </Label>
                        <CustomInputField
                          variant="outlined"
                          name={prompt}
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                        />
                      </LabelValueContainer>
                    </LabelContainer>
                    <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Model Type"}</Label>
                        <StyledSelectFormControl variant="outlined">
                          <Select
                            value={mediaType}
                            name={mediaType}
                            displayEmpty
                            onChange={(e) => setMediaType(e.target.value)}
                            renderValue={
                              mediaType !== ""
                                ? undefined
                                : () => (
                                    <Placeholder>
                                      {"Select Model Type"}
                                    </Placeholder>
                                  )
                            }
                            IconComponent={() => (
                              <StyledDropdownIcon
                                alt="select"
                                src={require("../../../assets/images/dropdownIcon.svg")}
                              />
                            )}
                          >
                            {ModelType.map((item, idx) => (
                              <StyledMenuItem
                                key={idx}
                                value={item.value}
                                disabled={item.disabled}
                              >
                                {item.label}
                              </StyledMenuItem>
                            ))}
                          </Select>
                        </StyledSelectFormControl>
                      </LabelValueContainer>
                    </LabelContainer>
                  </ModalParameterContainer>
                  <ModalGridContainer>
                    {/* <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Initial Prompt"}</Label>
                        <CustomInputField
                          variant="outlined"
                          name={prompt}
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                        />
                      </LabelValueContainer>
                    </LabelContainer> */}
                    {/* <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Model Type"}</Label>
                        <StyledSelectFormControl variant="outlined">
                          <Select
                            value={mediaType}
                            name={mediaType}
                            displayEmpty
                            onChange={(e) => setMediaType(e.target.value)}
                            renderValue={
                              mediaType !== ""
                                ? undefined
                                : () => (
                                    <Placeholder>
                                      {"Select Model Type"}
                                    </Placeholder>
                                  )
                            }
                            IconComponent={() => (
                              <StyledDropdownIcon
                                alt="select"
                                src={require("../../../assets/images/dropdownIcon.svg")}
                              />
                            )}
                          >
                            {ModelType.map((item, idx) => (
                              <StyledMenuItem
                                key={idx}
                                value={item.value}
                                disabled={item.disabled}
                              >
                                {item.label}
                              </StyledMenuItem>
                            ))}
                          </Select>
                        </StyledSelectFormControl>
                      </LabelValueContainer>
                    </LabelContainer>
                  </ModalParameterContainer>
                  <ModalGridContainer>
                    {/* <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Initial Prompt"}</Label>
                        <CustomInputField
                          variant="outlined"
                          name={prompt}
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                        />
                      </LabelValueContainer>
                    </LabelContainer> */}
                    {/* <LabelContainer>
                      <LabelValueContainer>
                        <Label>{"Model Type"}</Label>
                        <StyledSelectFormControl variant="outlined">
                          <Select
                            value={mediaType}
                            name={mediaType}
                            displayEmpty
                            onChange={(e) => setMediaType(e.target.value)}
                            renderValue={
                              mediaType !== ""
                                ? undefined
                                : () => (
                                    <Placeholder>
                                      {"Select Model Type"}
                                    </Placeholder>
                                  )
                            }
                            IconComponent={() => (
                              <StyledDropdownIcon
                                alt="select"
                                src={require("../../../assets/images/dropdownIcon.svg")}
                              />
                            )}
                          >
                            {ModelType.map((item, idx) => (
                              <StyledMenuItem
                                key={idx}
                                value={item.value}
                                disabled={item.disabled}
                              >
                                {item.label}
                              </StyledMenuItem>
                            ))}
                          </Select>
                        </StyledSelectFormControl>
                      </LabelValueContainer>
                    </LabelContainer> */}
                    {radioActions.map((item, index) => (
                      <LabelContainer>
                        <LabelValueContainer>
                          <CheckboxContainer style={{ marginTop: 12 }}>
                            <CustomCheckbox
                              name="checked"
                              value={item.value}
                              checked={item.checked}
                              onChange={(e) => item.props(e?.target?.checked)}
                            />
                            <CheckBoxLabel>{item.label}</CheckBoxLabel>
                          </CheckboxContainer>
                        </LabelValueContainer>
                      </LabelContainer>
                    ))}
                  </ModalGridContainer>
                </BoxContainer>
              ) : (
                <BoxContainer className="invoice">
                  <HeadingsLabel>{"Model Parameters"}</HeadingsLabel>
                  <ModalParameterContainer>
                    {dg_inputs.map((action, index) => (
                      <>
                        {action.type === "select" ? (
                          <LabelContainer>
                            <LabelValueContainer style={{ paddingTop: 5 }}>
                              <Label>{action.text}</Label>
                              <StyledSelectFormControl variant="outlined">
                                <Select
                                  value={action.value}
                                  name={action.name}
                                  onChange={(e) => action.props(e.target.value)}
                                  renderValue={
                                    action.name !== ""
                                      ? undefined
                                      : () => (
                                          <Placeholder>
                                            {action.placeholder}
                                          </Placeholder>
                                        )
                                  }
                                  IconComponent={() => (
                                    <StyledDropdownIcon
                                      alt="select"
                                      src={require("../../../assets/images/dropdownIcon.svg")}
                                    />
                                  )}
                                >
                                  {action.menuItem.map((element, index) => (
                                    <StyledMenuItem
                                      key={index}
                                      value={element.value}
                                      disabled={element.disabled}
                                    >
                                      {element.label}
                                    </StyledMenuItem>
                                  ))}
                                </Select>
                              </StyledSelectFormControl>
                            </LabelValueContainer>
                          </LabelContainer>
                        ) : (
                          <LabelContainer>
                            <LabelValueContainer>
                              <Label>{action.label}</Label>
                              <CustomInputField
                                variant="outlined"
                                name={action.name}
                                value={action.value}
                                type={action.type}
                                onChange={(e) => action.props(e.target.value)}
                              />
                            </LabelValueContainer>
                          </LabelContainer>
                        )}
                      </>
                    ))}
                  </ModalParameterContainer>
                  <Label>{"Keywords"}</Label>
                  {inputFields.map((inputField, index) => (
                    <DgKeywordContainer
                      className="transcription-settings"
                      key={index}
                    >
                      <CustomInputField
                        variant="outlined"
                        type="text"
                        name="keyword"
                        value={inputField.keyword}
                        onChange={(event) => handleInputChange(index, event)}
                        placeholder="Keyword"
                      />
                      <CustomInputField
                        variant="outlined"
                        type="text"
                        name="intensifier"
                        value={inputField.intensifier}
                        onChange={(event) => handleInputChange(index, event)}
                        placeholder="Intensifier"
                      />
                      <DeleteIcon
                        onClick={() => handleRemoveFields(index)}
                        src={require("../../../assets/images/removeIcon.svg")}
                      />
                    </DgKeywordContainer>
                  ))}
                  <ButtonWrapper>
                    <SecondaryCTAButton onClick={() => handleAddFields()}>
                      + Add New
                    </SecondaryCTAButton>
                  </ButtonWrapper>
                  <DgParameterContainer>
                    {deepgram_parameters.map((item) => (
                      <LabelContainer>
                        <LabelValueContainer>
                          <CheckboxContainer style={{ marginTop: 12 }}>
                            <CustomCheckbox
                              key={item.value}
                              name="checked"
                              value={item.value}
                              checked={dgParameters.includes(item.value)}
                              onChange={() => handleCheckboxChange(item.value)}
                            />
                            <CheckBoxLabel>{item?.label}</CheckBoxLabel>
                          </CheckboxContainer>
                        </LabelValueContainer>
                      </LabelContainer>
                    ))}
                  </DgParameterContainer>
                </BoxContainer>
              )}
            </StyledForm>
            <StyledForm
              style={{ padding: "20px 0px 20px 0px", marginLeft: "10px" }}
            >
              <BoxContainer>
                <HeadingsLabel>{"Audio Style"}</HeadingsLabel>
                <SettingsContainer className="invoice">
                  {audio_style_action.map((item, index) => (
                    <CheckboxContainer style={{ marginTop: 12 }}>
                      <CustomCheckbox
                        name="checked"
                        value={item.value}
                        checked={item.value === audioStyle}
                        onChange={() => {
                          if (item.name === "liveStreaming") {
                            setServer(0);
                            setAudioStyle(item.value);
                          }
                          setAudioStyle(item.value);
                        }}
                        // onChange={() => setAudioStyle(item.value)}
                      />
                      <CheckBoxLabel>{item?.label}</CheckBoxLabel>
                    </CheckboxContainer>
                  ))}
                </SettingsContainer>
              </BoxContainer>
            </StyledForm>
            {audioStyle === 2 ? (
              <StyledForm
                style={{ padding: "20px 0px 20px 0px", marginRight: "10px" }}
              >
                <BoxContainer className="invoice">
                  <HeadingsLabel>{"Periodic Split Parameters"}</HeadingsLabel>
                  <PeriodicSplitContainer>
                    {selectAction.map((action, index) => (
                      <>
                        <LabelContainer>
                          <LabelValueContainer style={{ paddingTop: 5 }}>
                            <Label>
                              {action.text}
                              {/* <span className="required-star"> *</span> */}
                            </Label>
                            {action.type === "select" ? (
                              <StyledSelectFormControl variant="outlined">
                                <Select
                                  value={action.value}
                                  name={action.name}
                                  onChange={(e) => action.props(e.target.value)}
                                  renderValue={
                                    action.name !== ""
                                      ? undefined
                                      : () => (
                                          <Placeholder>
                                            {action.placeholder}
                                          </Placeholder>
                                        )
                                  }
                                  IconComponent={() => (
                                    <StyledDropdownIcon
                                      alt="select"
                                      src={require("../../../assets/images/dropdownIcon.svg")}
                                    />
                                  )}
                                >
                                  {action.menuItem.map((element, index) => (
                                    <StyledMenuItem
                                      key={index}
                                      value={element.value}
                                      disabled={element.disabled}
                                    >
                                      {element.label}
                                    </StyledMenuItem>
                                  ))}
                                </Select>
                              </StyledSelectFormControl>
                            ) : (
                              <CustomInputField
                                variant="outlined"
                                type={action.type}
                                name={action.name}
                                value={action.value}
                                onChange={(e) => action.props(e.target.value)}
                              />
                            )}
                          </LabelValueContainer>
                        </LabelContainer>
                      </>
                    ))}
                  </PeriodicSplitContainer>
                </BoxContainer>
              </StyledForm>
            ) : null}
            {audioStyle === 1 ? (
              <StyledForm
                style={{ padding: "20px 0px 20px 0px", marginRight: "10px" }}
              >
                <BoxContainer className="invoice">
                  <HeadingsLabel>{"Split on Silence Parameters"}</HeadingsLabel>
                  <ModalParameterContainer>
                    {split_on_slience.map((modal, index) => (
                      <LabelContainer>
                        <LabelValueContainer>
                          <div
                            className="form-fields"
                            style={{ paddingTop: 5 }}
                          >
                            <FormLabel
                              style={{
                                fontSize: 12,
                                fontFamily: theme.fonts.primaryFontSemiBold,
                                color: "#949ab0",
                              }}
                            >
                              {modal.label}
                            </FormLabel>
                            <CustomInputField
                              variant="outlined"
                              type={modal.type}
                              name={modal.name}
                              value={modal.value}
                              onChange={(e) => modal.props(e.target.value)}
                            />
                          </div>
                        </LabelValueContainer>
                      </LabelContainer>
                    ))}
                  </ModalParameterContainer>
                </BoxContainer>
              </StyledForm>
            ) : null}
            <StyledForm
              style={{ padding: "20px 0px 20px 0px", marginLeft: "10px" }}
            >
              <BoxContainer>
                <HeadingsLabel>{"Audio Enhancement"}</HeadingsLabel>
                <ServerAction>
                  {actionsData.map((item) => (
                    <LabelContainer>
                      <LabelValueContainer>
                        <CheckboxContainer style={{ marginTop: 12 }}>
                          <CustomCheckbox
                            name="checked"
                            value={item.value}
                            checked={item.checked}
                            onChange={(e) => item.props(e.target.checked)}
                          />
                          <CheckBoxLabel>{item?.text}</CheckBoxLabel>
                        </CheckboxContainer>
                      </LabelValueContainer>
                    </LabelContainer>
                  ))}
                </ServerAction>
              </BoxContainer>
            </StyledForm>
          </FormContainer>
          <ButtonContainer>
            <PrimaryOutlinedCTAButton
              style={{ width: "38%", marginLeft: "35px", padding: "8px 8px" }}
              onClick={() => setModal(false)}
            >
              {"Cancel"}
            </PrimaryOutlinedCTAButton>
            <PrimaryCTAButton
              style={{ width: "38%", marginRight: "42px", padding: "8px 8px" }}
              onClick={() => onFormSubmit(id)}
            >
              {"Submit"}
            </PrimaryCTAButton>
          </ButtonContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default SettingModal;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 75%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  line-height: 1.71;
  backface-visibility: hidden;
  max-height: 535px;
  overflow: auto;
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledForm = styled.div``;

const LabelContainer = styled.div`
  margin-right: 25px;
  margin-bottom: 5px;
  @media (min-width: 640px) {
    margin-right: 0;
  }
`;

const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.9;
  color: ${COLORS.INPUT_LABEL};
`;

const HeadingsLabel = styled(FormLabel)(() => ({
  fontFamily: theme.fonts.primaryFontSemiBold,
  color: "#567189",
  fontSize: "14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const BoxContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  border: solid 1px #b3c8cf;
  padding-top: 14px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 10px;
`;

const ModalGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  padding-bottom: 12px;
  justify-content: space-around;
`;

const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 10px;
`;

const PeriodicSplitContainer = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 0.5fr 0.5fr 0.5fr;
  grid-column-gap: 10px;
`;

const ModalParameterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  // grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
`;

const ServerAction = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 10px;
`;

const DgParameterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
`;

const DgKeywordContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin-top: 15px;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    padding-bottom: 20px;
    padding-top: 10px;
  }
`;
const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  margin-top: 10px;
`;
